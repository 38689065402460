import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { loadThoughtsMetadata } from '../components/unspokenThoughts/LoadMetadata';
import ArtPlaceholder from '../assets/images/Art/ArtPlaceholder.svg';
import SearchBar from '../components/common/SearchBar';
import LayOut from '../components/common/Layout';
import Heading from '../components/common/Heading';
import { Routes } from '../utils/Routes';

export default function Thoughts() {
  const [loadedImages, setLoadedImages] = useState<{ [id: string]: boolean }>(
    {}
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [thoughtArt, setthoughtArt] = useState<any[]>([]);

  // Preload images and initialize metadata
  useEffect(() => {
    const artData = loadThoughtsMetadata();
    const preloadThumbnails = async () => {
      await Promise.all(
        artData.map((art) => {
          return new Promise<void>((resolve) => {
            const img = new Image();
            img.src = art.thumbnail;
            img.onload = () => resolve();
            img.onerror = () => resolve();
          });
        })
      );
      setthoughtArt(artData);
    };

    preloadThumbnails();
  }, []);

  const handleImageLoad = (id: string) => {
    setLoadedImages((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const filteredThoughts = thoughtArt.filter((art) =>
    art.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedThoughts = filteredThoughts.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return (
    <LayOut layoutStyle="bg-white">
      <div className="pt-16 md:pt-10 lg:pt-14">
        <Heading text="Unspoken Thoughts" customStyle="text-custom-coffee" />
        <section className="flex flex-col items-center p-4">
          {/* Search Bar */}
          <div className="w-full max-w-auto mb-8">
            <SearchBar
              query={searchQuery}
              onSearch={setSearchQuery}
              placeholder="Search ..."
            />
          </div>

          {/* Thoughts Grid */}
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {sortedThoughts.length > 0 ? (
              sortedThoughts.map((art) => (
                <div
                  key={art.id}
                  className="p-4 border border-gray-400 shadow-lg rounded-lg bg-white hover:shadow-xl hover:scale-105 transition-transform duration-300 ease-in-out"
                >
                  <Link to={`${Routes.Thoughts}/${art.id}`}>
                    <div className="cursor-pointer w-full h-48 relative rounded-lg overflow-hidden">
                      {!loadedImages[art.id] && (
                        <img
                          src={ArtPlaceholder}
                          alt="Thoughts Placeholder"
                          className="w-full h-full object-cover rounded-lg"
                        />
                      )}
                      <img
                        src={art.thumbnail}
                        alt={`${art.title} Thought`}
                        className={`w-full h-full rounded-lg transition-opacity duration-500 ${
                          loadedImages[art.id] ? 'block' : 'hidden'
                        }`}
                        onLoad={() => handleImageLoad(art.id)}
                        onError={(e) => {
                          console.error(
                            `Error loading image for ${art.title}:`,
                            e
                          );
                          e.currentTarget.src = ArtPlaceholder; // Fallback to placeholder
                        }}
                        loading="lazy"
                      />
                    </div>
                  </Link>
                  <div className="mt-4 text-center">
                    <Link to={`${Routes.Thoughts}/${art.id}`}>
                      <h2 className="text-lg font-semibold text-gray-800 hover:underline">
                        {art.title || 'Untitled'}
                      </h2>
                    </Link>
                    <p className="text-sm text-gray-600 italic">
                      {art.description || 'Explore the mood.'}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 col-span-full">
                No results found for "{searchQuery}"
              </div>
            )}
          </div>
        </section>
      </div>
    </LayOut>
  );
}
