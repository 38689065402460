export const SalesStatus = {
  AVAILABLE: 'available',
  SOLD: 'sold',
  NOT_FOR_SALE: 'not for sale',
  INQUIRE_FOR_PRICE: 'inquire for price',
  COMMISSION_AVAILABLE: 'commission available',
} as const;

export const Currencies = {
  KENYA_SHILLING: 'KES',
  US_DOLLAR: 'USD',
};

export const APIs = {
  message: '/api/message',
  collectiveMood: '/api/mood',
};
