// Happy or Sad Metadata.
import HappySadSVG from '../../../assets/images/Thoughts/HappySad.svg';
import InfoContent from './InfoContent';

const HappySadMetadata = {
  id: 'HappySad',
  title: 'Happy or Sad?',
  description: 'Individualism and the Collective.',
  thumbnail: HappySadSVG,
  featured: true,
  info: InfoContent,
};

export default HappySadMetadata;
