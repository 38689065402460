type HeadingProps = {
  text: string;
  customStyle?: string;
};

export default function Heading({ text, customStyle }: HeadingProps) {
  return (
    <h1 className={`text-3xl lg:text-5xl font-bold mb-4 ${customStyle}`}>
      {text}
    </h1>
  );
}
