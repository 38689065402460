export default function ErrorFallback() {
  return (
    <div className="p-5 text-center">
      <h2 className="text-red-500">Oops! Something went wrong.</h2>
      <p className="text-gray-500">
        Please try refreshing the page or return to the homepage.
      </p>
    </div>
  );
}
