// Load Thoughts Art Metadata
interface ThoughtMetadata {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  featured?: boolean;
  info?: React.ComponentType;
}

export const loadThoughtsMetadata = (): ThoughtMetadata[] => {
  /*
  Only match Metadata.ts in subdirectories
  ^\.\/: Matches files in the current directory.
  [^/]+: Ensures the file is inside a subdirectory (e.g., HappySad).
  /Metadata\.ts$: Ensures the file is named Metadata.ts
  */
  const context = require.context('./', true, /^\.\/[^/]+\/Metadata\.ts$/);
  return context.keys().map((key) => {
    const metadata = context(key)?.default;
    if (!metadata || !metadata.id || !metadata.thumbnail) {
      console.error(`Invalid metadata in file: ${key}`, metadata);
    }
    return metadata as ThoughtMetadata;
  });
};
