// Art Info
const InfoContent = () => {
  return (
    <div className="text-left">
      <h2 className="text-3xl font-bold mb-4 text-gray-800">
        Individualism and the Collective
      </h2>
      <div className="text-lg text-gray-700 flex flex-col gap-2">
        <b>"How much of yourself belongs to you and how much to the whole?"</b>
        <p>
          The "Happy or Sad?" piece challenges a fundamental tension: The individuality
          of self and the unity of the collective. Each vote is an act of
          self-expression — a choice to be seen, heard and acknowledged. Yet, the
          final image belongs to the majority, not the individual.
        </p>
        <p>
          This is the paradox of human connection. The collective is made of
          individuals, yet it often moves beyond them, becoming a force that
          shapes, defines and sometimes silences. The individual, distinct and
          singular, can stand apart but cannot escape the weight of the whole.
        </p>
        <b className="mt-5">The Balance of Power</b>
        <p>
          Individuality is the seed of innovation, rebellion and change. The
          collective, in turn, offers stability, tradition and shared purpose.
          "Happy or Sad?" explores this delicate balance, where one cannot exist
          without the other.
        </p>
        <p>
          The art does not ask for answers — it invites reflection. It is a mirror
          for our human experience, asking not who is right, but what are we
          willing to become together.
        </p>
      </div>
      <blockquote className="border-l-4 border-yellow-500 pl-4 italic text-gray-500 mt-10">
        "You are not lost in the whole but neither are you its master."
      </blockquote>
    </div>
  );
};

export default InfoContent;
