import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../utils/Routes';
import NavItem, { NavItemProps } from './NavItem';

const NavRoutes = [
  { route: Routes.Home, title: 'Home' },
  { route: Routes.About, title: 'About' },
  { route: Routes.Art, title: 'Art' },
  { route: Routes.Thoughts, title: 'Thoughts' },
  { route: Routes.Music, title: 'Music' },
  // { route: Routes.Thoughts, title: 'Thoughts' },
  { route: Routes.Contact, title: 'Contact' },
];

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle mobile menu
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="bg-black text-white px-2 fixed w-full z-10">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* TODO - Add Logo */}
        <Link
          to={Routes.Home}
          className="text-2xl font-bold hover:text-custom-stone"
        >
          <div className="flex gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-9 w-6"
            >
              {/* Eye outline */}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 4.5C7 4.5 2.73 9 2 12s5 7.5 10 7.5 9.27-4.5 10-7.5S17 4.5 12 4.5z"
              />
              {/* Pupil */}
              <circle cx="12" cy="12" r="2.5" fill="black" />
            </svg>
            <div>Unspoken Creations</div>
          </div>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          {NavRoutes.map((props: NavItemProps) => (
            <NavItem
              title={props.title}
              route={props.route}
              key={props.title}
            />
          ))}
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'
              }
            />
          </svg>
        </button>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <nav className="md:hidden">
          {NavRoutes.map(({ title, route }) => (
            <NavItem
              title={title}
              route={route}
              key={title}
              className="block py-4 px-6 text-white border-b border-b-custom-stone"
            />
          ))}
        </nav>
      )}
    </header>
  );
}
