import Heading from '../components/common/Heading';
import LayOut from '../components/common/Layout';
import { Routes } from '../utils/Routes';

export default function About() {
  return (
    <LayOut layoutStyle="bg-white">
      <div className="container mx-auto my-12 px-5 lg:px-20">
        <div className="mb-10">
          <Heading
            text="Unspoken Creations"
            customStyle="text-custom-coffee pt-4"
          />
          <p className="text-gray-500 mt-4 text-lg italic">
            "Where the unsaid finds its form through art, music and thought."
          </p>
        </div>

        {/* About Section */}
        <div className="max-w-4xl mx-auto text-left">
          <p className="text-gray-600 text-lg leading-relaxed mb-6">
            Not everything meaningful can be put into words. Each creation tells
            its own story. But the beauty of it lies in the fact that the story
            remains unspoken — left for you, the viewer, to discover on your own
            terms.
          </p>
          <p className="text-gray-600 text-lg leading-relaxed">
            You are invited to explore the uncharted territories of emotion,
            thought and creativity that hope to inspire reflection, connection
            and a sense of wonder in every visitor.
          </p>

          {/* Closing Statement */}
          <div className="mt-16">
            <h2 className="text-2xl md:text-3xl font-semibold text-gray-700 mb-4">
              Join the Unspoken Journey
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              Step into the world of Unspoken Creations, where every artwork,
              every sound and every thought holds a mystery. It’s a place where
              you become part of the creation.{' '}
              <span className="text-gray-400">
                Find your own meaning in the unspoken.
              </span>
            </p>
          </div>

          {/* Call to Action */}
          <div className="mt-8">
            <a
              href={Routes.Art}
              className="inline-block bg-custom-coffee text-white font-bold py-3 px-6 rounded-lg hover:bg-custom-sand transition duration-300"
            >
              Explore Unspoken Creations
            </a>
          </div>
        </div>
      </div>
    </LayOut>
  );
}
