import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { loadThoughtsMetadata } from '../unspokenThoughts/LoadMetadata';
import { ArtData } from '../../utils/ArtData';
import { Routes } from '../../utils/Routes';

interface SubHeadingProps {
  text: string;
  className?: string;
}

function SubHeading({ text, className }: SubHeadingProps) {
  return <h2 className={`text-xl md:text-2xl font-bold ${className}`}>{text}</h2>;
}

export default function FeaturedArt() {
  const [featuredArt, setFeaturedArt] = useState<any[]>([]);

  useEffect(() => {
    // Combine featured items from thoughts and Unspoken Art
    const thoughtsData = loadThoughtsMetadata();
    const featuredThoughts = thoughtsData.filter(
      (art) => art?.featured === true
    );

    const featuredUnspokenArt = ArtData.filter(
      (art) => art.metadata?.featured === true
    );

    // Combine and shuffle featured items
    const combinedFeatured = [
      ...featuredThoughts,
      ...featuredUnspokenArt,
    ].sort(() => 0.5 - Math.random());
    setFeaturedArt(combinedFeatured.slice(0, 3)); // Limit to 3 items
  }, []);

  return (
    <div className="w-full flex flex-col items-start bg-gray-800/70 p-6 rounded-lg shadow-lg gap-6">
      <SubHeading text="Featured Art" className="text-gray-200" />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
        {featuredArt.map((art) => {
          // Determine the correct route and properties based on the source
          const isUnspokenArt = !!art.metadata; // If the `metadata` property exists, it's Unspoken Art
          const linkTo = isUnspokenArt
            ? `${Routes.Art}/${art.metadata.id}`
            : `${Routes.Thoughts}/${art.id}`;
          const imageSrc = isUnspokenArt ? art.image : art.thumbnail;
          const title = isUnspokenArt ? art.metadata.title : art.title;

          return (
            <div
              key={art.id || art.metadata.id}
              className="flex flex-col items-center bg-gray-900 border border-gray-700 rounded-lg p-4 shadow-md hover:shadow-2xl hover:scale-105 hover:border-yellow-500 transition-all duration-300"
            >
              <Link to={linkTo}>
                <img
                  src={imageSrc}
                  alt={title}
                  className="w-full h-32 rounded-lg mb-2"
                  style={{ objectFit: 'contain' }}
                />
              </Link>
              <h3 className="text-md font-semibold text-gray-200 truncate hover:underline">
                <Link to={linkTo}>{title || 'Untitled'}</Link>
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
}
