// Thoughts Art Details
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { loadThoughtsMetadata } from '../components/unspokenThoughts/LoadMetadata';
import LayOut from '../components/common/Layout';
import Heading from '../components/common/Heading';
import { Routes } from '../utils/Routes';
import SubHeading from '../components/common/SubHeading';

const ThoughtDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const artPieces = loadThoughtsMetadata();
  const artPiece = artPieces.find((art) => art.id === id);

  if (!artPiece) {
    return (
      <LayOut layoutStyle="bg-white">
        <section className="flex flex-col items-center p-5">
          <div className="container mx-auto my-8 p-4">
            {/* Back to Thoughts Link */}
            <div className="text-left mb-6">
              <Link
                to={Routes.Thoughts}
                className="text-blue-400 hover:text-blue-700 underline text-lg inline-flex items-center"
              >
                &#8592; Back to Thoughts
              </Link>
            </div>
            <Heading
              customStyle="text-md text-gray-400 text-center"
              text="Thought Not Found!"
            />
          </div>
        </section>
      </LayOut>
    );
  }

  const Component = React.lazy(() =>
    import(`../components/unspokenThoughts/${id}/${id}`).catch(() => {
      return (
        <LayOut>
          <SubHeading text="Not Found. Try Again!" customStyle="py-20" />
        </LayOut>
      );
    })
  );

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <LayOut>
        <div className="">
          <Component />
        </div>
      </LayOut>
    </React.Suspense>
  );
};

export default ThoughtDetails;
