import Heading from '../components/common/Heading';
import LayOut from '../components/common/Layout';
import UnspokenArt from '../components/unspokenArt/UnspokenArt';

export default function Art() {
  return (
    <LayOut layoutStyle="bg-white">
      <div className="pt-16 md:pt-10 lg:pt-14">
        <Heading text="Unspoken Art" customStyle="text-custom-coffee" />
        <section className="flex p-2">
          <div>
            <UnspokenArt />
          </div>
        </section>
      </div>
    </LayOut>
  );
}
