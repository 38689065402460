import React, { useState, useEffect, useMemo } from 'react';

const BinarySequence: React.FC = () => {
  const binarySequences = useMemo(
    () => [
      '01000100 01110010 01100101 01100001 01101101', // Dream
      '01101001 01101110', // in
      '01000011 01101111 01100100 01100101', // Code
    ],
    []
  );

  const maxLength = Math.max(...binarySequences.map((seq) => seq.length)); // Find the longest sequence
  const [currentSequence, setCurrentSequence] = useState(''); // Current visible bits
  const [currentIndex, setCurrentIndex] = useState(0); // Current word
  const [bitIndex, setBitIndex] = useState(0); // Current bit position

  useEffect(() => {
    const sequence = binarySequences[currentIndex];
    const timer = setInterval(() => {
      if (bitIndex < sequence.length) {
        setCurrentSequence((prev) => prev + sequence[bitIndex]); // Append the next bit
        setBitIndex((prev) => prev + 1); // Move to the next bit
      } else {
        setTimeout(() => {
          // Transition to the next word
          setCurrentIndex((prevIndex) =>
            prevIndex < binarySequences.length - 1 ? prevIndex + 1 : 0
          );
          setBitIndex(0);
          setCurrentSequence(''); // Reset for the next word
        }, 1000); // Pause between words
      }
    }, 100); // Delay between each bit

    return () => clearInterval(timer); // Cleanup interval
  }, [bitIndex, currentIndex, binarySequences]);

  // Pad the sequence to ensure consistent height
  const paddedSequence = currentSequence.padEnd(maxLength, ' ');

  return (
    <div className="text-center mt-4" style={{ minHeight: '4rem' }}>
      <p className="text-lg md:text-xl text-gray-200 font-mono">
        {paddedSequence}
      </p>
    </div>
  );
};

export default BinarySequence;
