import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import LayOut from '../components/common/Layout';
import SubHeading from '../components/common/SubHeading';
import { loadThoughtsMetadata } from '../components/unspokenThoughts/LoadMetadata';
import { Routes } from '../utils/Routes';

export default function Info() {
  const { id } = useParams<{ id: string }>();
  const artPieces = loadThoughtsMetadata();
  const artPiece = artPieces.find((art) => art.id === id);

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  if (!artPiece || !artPiece.info) {
    return (
      <LayOut layoutStyle="bg-gray-100">
        <div className="py-20 text-center text-gray-500">
          {/* Back to Thoughts Link */}
          <div className="text-left mb-6">
            <Link
              to={Routes.Thoughts}
              className="text-blue-400 hover:text-blue-700 underline text-lg inline-flex items-center"
            >
              &#8592; Back To Thoughts
            </Link>
          </div>
          <SubHeading text="Not Found" customStyle="mb-4" />
          <p>Sorry, we couldn't find details for this thought.</p>
        </div>
      </LayOut>
    );
  }

  const InfoComponent = artPiece.info;

  return (
    <LayOut layoutStyle="bg-gray-100">
      <div className="container mx-auto p-6">
        {/* Back to Thoughts Link */}
        <div className="text-left mb-6 mt-10 lg:mt-0">
          <Link
            to={`${Routes.Thoughts}/${id}`}
            className="text-blue-400 hover:text-blue-700 underline text-lg inline-flex items-center"
          >
            &#8592; Back
          </Link>
        </div>
        <div className="text-gray-300 leading-8">
          <InfoComponent />
        </div>
      </div>
    </LayOut>
  );
}
