// Can You See Me Art Info
const InfoContent = () => {
  return (
    <div className="text-left">
      <h2 className="text-3xl font-bold mb-4 text-gray-800">
        The Weight of Being Watched
      </h2>
      <div className="text-lg text-gray-700 flex flex-col gap-2">
        <p>
          The fear of being observed is deeply tied to vulnerability. To be
          observed is to be seen, often in ways we cannot control. It exposes
          us, making us aware of our flaws, imperfections and the judgments of
          others. Observation feels like a form of power: The observer defines
          how we are perceived and by extension, how we exist in their eyes.
        </p>
        <b className="mt-5"> "Observation as Transformation"</b>
        <p>
          The famous observer effect in quantum mechanics shows that even at the
          most fundamental level of reality, observation changes outcomes. A
          particle can behave as a wave until it's observed, collapsing into a
          fixed state. This mirrors the human experience: When unobserved, we
          feel free to flow like a wave — unbound by judgment or expectation.
          But under observation, we collapse into a more definitive state,
          shaped by the external gaze.
        </p>
        <p>
          This transformation forces us to confront the dichotomy between how we
          see ourselves and how others perceive us. Observation becomes a
          mirror, reflecting parts of ourselves we might otherwise ignore. It is
          both a revelation and a restraint, making it a profoundly unsettling
          experience.
        </p>

        <b className="mt-5">
          The Algorithmic Gaze - “Machines see us, but do they understand us?”
        </b>
        <p>
          Algorithms are the new eyes of the digital age. They observe not
          through sight, but through patterns — clicks, swipes, purchases and
          searches. Every action feeds the algorithmic gaze, creating a digital
          version of ourselves that is often more revealing than the real one.
        </p>
        <p>
          Yet, this gaze is inherently reductive. Algorithms simplify
          complexity, categorizing us into neat profiles and predictive
          behaviors. While they can anticipate what we might want, they cannot
          grasp the nuances of why. The result is a form of observation that is
          both deeply personal and profoundly impersonal — a paradox that shapes
          our digital lives.
        </p>
        <b className="mt-5">"The Fear of Being Seen"</b>
        <p>
          The fear of observation lies in the collision between freedom and
          control. To be observed is to relinquish autonomy over how we exist.
          Privacy offers the sacred space to be authentic, without the weight of
          scrutiny. Yet, observation is also a tool for growth. It reveals
          truths about us — our actions, our motivations and even our
          identities.
        </p>
        <p>
          At the core, being observed means being changed. And while change is
          often necessary and transformative, it can feel like a loss of who we
          were before. This tension between the desire to be seen and the fear
          of exposure, defines our complex relationship with observation.
        </p>
      </div>
      <blockquote className="border-l-4 border-yellow-500 pl-4 italic text-gray-500 mt-10">
        "To be observed is to become a performer."
      </blockquote>
    </div>
  );
};

export default InfoContent;
